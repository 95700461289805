import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import useView from '@tmap/mmm-core/redux/views/useView';

import useRequireAuth from '../../hooks/useRequireAuth';
import useCurrentUser from '../../hooks/useCurrentUser';
import { getCommunitiesOfMember } from '../../actions/communityMembers';

import * as navConfig from './navConfig';
import HeaderBar from './headerBar';
import HeaderContext from './HeaderContext';

export default function Header(props) {
  const {
    color = 'transparent', // transparent|default
    elevation = color === 'transparent' ? 0 : 2,
  } = props;

  const user = useCurrentUser();
  const authUser = useAuth0();
  const { isAuthenticated } = authUser;
  const { requireAuthRedirect } = useRequireAuth();
  const handleLogout = useCallback(() => {
    authUser.logout({ logoutParams: { returnTo: window.location.origin } });
  }, [authUser]);
  const handleSignUp = useCallback(() => {
    requireAuthRedirect();
  }, [requireAuthRedirect]);
  const handleSignIn = useCallback(() => {
    requireAuthRedirect('', { screenHint: 'login' });
  }, [requireAuthRedirect]);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuOpen = Boolean(menuAnchor);
  const handleOpenMenu = useCallback((e) => { setMenuAnchor(e.currentTarget); }, []);
  const handleCloseMenu = useCallback(() => { setMenuAnchor(null); }, []);

  const [myCommunities] = useView(
    'header-my-communities',
    getCommunitiesOfMember(user),
  );

  const hasCommunity = isAuthenticated && myCommunities.length > 0;
  const headerMenuSx = useMemo(() => navConfig.headerMenuSx({
    hasCommunity,
  }), [
    hasCommunity,
  ]);
  const headerDrawerSx = useMemo(() => navConfig.headerDrawerSx({
    hasCommunity,
  }), [
    hasCommunity,
  ]);
  const navLinks = useMemo(() => navConfig.navLinks({
    hasCommunity,
    myCommunities,
    handleLogout,
  }), [
    hasCommunity,
    myCommunities,
    handleLogout,
  ]);
  const navGroups = useMemo(() => navConfig.navGroups({
    navLinks,
    isAuthenticated,
  }), [
    navLinks,
    isAuthenticated,
  ]);

  const headerData = useMemo(() => ({
    color,
    elevation,
    user,
    authUser,
    isAuthenticated,
    menuAnchor,
    handleLogout,
    handleSignUp,
    handleSignIn,
    isMenuOpen,
    handleOpenMenu,
    handleCloseMenu,
    headerMenuSx,
    headerDrawerSx,
    hasCommunity,
    myCommunities,
    navGroups,
    navLinks,
  }), [
    color,
    elevation,
    user,
    authUser,
    isAuthenticated,
    menuAnchor,
    handleLogout,
    handleSignUp,
    handleSignIn,
    isMenuOpen,
    handleOpenMenu,
    handleCloseMenu,
    headerMenuSx,
    headerDrawerSx,
    hasCommunity,
    myCommunities,
    navGroups,
    navLinks,
  ]);

  return (
    <HeaderContext.Provider value={headerData}>
      <HeaderBar />
    </HeaderContext.Provider>
  );
}
