import React from 'react';
import { useRouter } from 'next/router';

import { styled } from '@tmap/mmm-style-guide/src/styled';

import LinkedMenuItem from '../linkedMenuItem';

const StyledLinkedMenuItem = styled(LinkedMenuItem)(() => ({
  whiteSpace: 'nowrap',
}));

export default function NavButton(props) {
  const {
    href,
    disableActiveHighlight = false,
    children,
    ...otherProps
  } = props;
  const router = useRouter();
  const isActive = !disableActiveHighlight && new RegExp(`^${href}([#?].*)?$`).test(router.asPath);

  return (
    <StyledLinkedMenuItem
      IconProps={isActive ? { variant: 'contained', color: 'secondary' } : undefined}
      href={href}
      {...otherProps}
    >
      {children}
    </StyledLinkedMenuItem>
  );
}
