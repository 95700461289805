import React from 'react';
import NavListItem from '@tmap/mmm-style-guide/src/NavListItem';
import Link from 'next/link';

const LinkedMenuItem = React.forwardRef((props, ref) => {
  const {
    href, children, ...otherProps
  } = props;
  return <Link href={href}>
    <a>
      <NavListItem {...otherProps} ref={ref}>{children}</NavListItem>
    </a>
  </Link>
});

export default LinkedMenuItem;