import React, {
  useContext,
} from 'react';

import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

import { fgColor } from './navConfig';
import HeaderContext from './HeaderContext';
import NavButton from './navButton';

const RelocGroupGrid = styled(Grid, {
  shouldForwardProp: (p) => !['location', 'show'].includes(p),
})(({
  theme,
  show,
}) => ({
  display: 'none',
  ...Object.fromEntries(Object.entries(show).map(([bk, sh]) => ([
    theme.breakpoints.up(bk),
    { display: sh ? 'block' : 'none' },
  ]))),
}));

const RelocNavGrid = styled(Grid, {
  shouldForwardProp: (p) => !['color', 'location', 'relocation'].includes(p),
})(({
  theme,
  color,
  location,
  relocation,
}) => ({
  color: fgColor(theme, color),
  display: 'none',
  ...Object.fromEntries(Object.entries(relocation).map(([bk, loc]) => ([
    theme.breakpoints.up(bk),
    { display: loc === location ? 'block' : 'none' },
  ]))),
}));

export default function RelocNavGroup(props) {
  const {
    noLabel = false,
    location,
  } = props;
  const {
    navLinks,
    navGroups,
    isAuthenticated,
    color,
  } = useContext(HeaderContext);
  const { label: groupLabel, show } = navGroups[location];

  return (
    <RelocGroupGrid item show={show}>
      <Grid container direction='column'>
        {!noLabel && groupLabel && (
          <Grid item>
            <Typography
              variant='subtitle2'
              color='text.secondary'
              paddingLeft={2}
              paddingBottom={2}
            >
              {groupLabel}
            </Typography>
          </Grid>
        )}
        {
          Object.entries(navLinks)
            .filter(([, { auth = () => true }]) => auth(isAuthenticated))
            .map(([key, { relocation, label, button }]) => (
              <RelocNavGrid
                item
                key={`reloc-${location}-${key}`}
                color={color}
                location={location}
                relocation={relocation}
              >
                <NavButton {...button}>
                  {label}
                </NavButton>
              </RelocNavGrid>
            ))
        }
      </Grid>
    </RelocGroupGrid>
  );
}
