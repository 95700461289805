import React, {
  useContext,
} from 'react';
import Img from 'next/image';

import fullLogo from '@tmap/mmm-style-guide/src/common/mmm-full-logo.png';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { Drawer } from '@tmap/mmm-style-guide/src/Drawer';
import {
  Close,
} from '@tmap/mmm-style-guide/src/Icon';

import HeaderContext from './HeaderContext';
import RelocNavGroup from './relocNavGroup';
import AuthButtons from './authButtons';

const DrawerRootGrid = styled(Grid)(({ theme, spacing }) => ({
  height: `calc(100% + ${theme.spacing(spacing)})`,
}));

export default function HeaderDrawer() {
  const {
    isMenuOpen,
    handleCloseMenu,
    isAuthenticated,
    hasCommunity,
    headerDrawerSx,
  } = useContext(HeaderContext);
  return (
    <Drawer
      anchor='right'
      open={isMenuOpen}
      onClose={handleCloseMenu}
      sx={headerDrawerSx}
    >
      <DrawerRootGrid
        container
        direction='column'
        flexWrap='nowrap'
        justifyContent='space-between'
        spacing={3}
        paddingY={1}
      >
        <Grid item>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <Grid
                container
                flexWrap='nowrap'
                justifyContent='space-between'
                spacing={2}
                paddingLeft={2}
                paddingRight={1}
              >
                <Grid item display='flex' alignItems='center'>
                  <Img src={fullLogo} alt='Make My Move' width={2001 / 12} height={262 / 12} />
                </Grid>
                <Grid item>
                  <IconButton onClick={handleCloseMenu}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='column' spacing={3}>
                <RelocNavGroup location='first' />
                <RelocNavGroup location='browse' />
                {hasCommunity && (
                  <RelocNavGroup location='communities' />
                )}
                <RelocNavGroup location='resources' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='column' spacing={1}>
            <RelocNavGroup location='final' />
            {!isAuthenticated && (
              <Grid item>
                <Box paddingX={2}>
                  <AuthButtons />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DrawerRootGrid>
    </Drawer>
  );
}
