import locationLabelText from '@tmap/mmm-core/locationLabelText';
import {
  AutoAwesome,
  DescriptionOutlined,
  GroupOutlined,
  HomeIconOutlined,
  LocalOfferOutlined,
  Logout,
  Newspaper,
  HomePin,
} from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import ProfileAvatar from '../profileAvatar';

export const fgColor = (theme, colorString) => ({
  transparent: theme.palette.common.white,
  default: theme.palette.text.secondary,
}[colorString] || theme.palette.common.white);

const StyledProfileAvatar = styled(ProfileAvatar, {
  shouldForwardProp: (p) => p !== 'color',
})(({ theme, color }) => ({
  padding: '0',
  color: {
    transparent: theme.palette.primary.veryDark,
    default: theme.palette.common.white,
  }[color] || theme.palette.common.white,
  backgroundColor: fgColor(theme, color),
  overflow: 'hidden',
}));

export const headerMenuSx = ({ hasCommunity }) => (
  hasCommunity
    ? { display: { xs: 'none' } }
    : { display: { xs: 'none', md: 'unset' } }
);

export const headerDrawerSx = ({ hasCommunity }) => (
  hasCommunity
    ? { display: {} }
    : { display: { md: 'none' } }
);

export const navLinks = ({ hasCommunity, myCommunities, handleLogout }) => ({
  home: {
    label: 'Home',
    button: {
      href: '/',
      Icon: HomeIconOutlined,
    },
    relocation: { xs: 'first' },
  },
  offers: {
    label: 'Offers',
    button: {
      href: '/get-paid',
      Icon: LocalOfferOutlined,
    },
    relocation: { xs: 'browse', md: 'main' },
  },
  communities: {
    label: 'Communities',
    button: {
      href: '/moving-living',
      Icon: GroupOutlined,
    },
    relocation: hasCommunity ? {
      xs: 'browse',
      lg: 'main',
    } : {
      xs: 'browse',
      md: 'main',
    },
  },
  ...Object.fromEntries(myCommunities.map((c, i) => ([
    `my-community-${c._id}`,
    {
      label: locationLabelText(c.locationText, c.regionText, c.locationGeo?.state_abbreviation),
      button: {
        href: `/moving-living/${c.slug.current}`,
        Icon: HomePin,
      },
      relocation: {
        xs: 'communities',
        sm: i === 0 ? 'main' : 'communities',
      },
    },
  ]))),
  articles: {
    label: 'Articles',
    button: {
      href: '/articles',
      Icon: DescriptionOutlined,
    },
    relocation: hasCommunity ? {
      xs: 'resources',
      lg: 'main',
    } : {
      xs: 'resources',
      lg: 'main',
    },
  },
  howItWorks: {
    label: 'How it Works',
    button: {
      href: '/how-it-works',
      Icon: AutoAwesome,
    },
    relocation: hasCommunity ? {
      xs: 'resources',
    } : {
      xs: 'resources',
      lg: 'main',
    },
  },
  news: {
    label: 'News',
    button: {
      href: '/news',
      Icon: Newspaper,
    },
    relocation: { xs: 'resources' },
  },
  profile: {
    label: 'Profile',
    button: {
      href: '/myprofile',
      Icon: StyledProfileAvatar,
      IconProps: {
        size: 32,
        variant: 'contained',
        borderColor: (palette) => palette.action.active,
        borderThickness: '1px',
      },
      disableActiveHighlight: true,
    },
    relocation: { xs: 'final' },
    auth: (a) => Boolean(a),
  },
  logout: {
    label: 'Logout',
    button: {
      href: '#',
      Icon: Logout,
      onClick: handleLogout,
      disableActiveHighlight: true,
    },
    relocation: { xs: 'final' },
    auth: (a) => Boolean(a),
  },
});

export const navGroups = ({ navLinks: links, isAuthenticated }) => {
  const bks = ['xs', 'sm', 'md', 'lg', 'xl'];
  const groups = {
    main: { label: null, show: {} },
    first: { label: null, show: {} },
    browse: { label: 'BROWSE', show: {} },
    communities: { label: 'MY COMMUNITIES', show: {} },
    resources: { label: 'NEWS & RESOURCES', show: {} },
    final: { label: null, show: {} },
  };
  // compute the whole group visibility using items visiblities
  Object.keys(groups).forEach((location) => {
    const groupIsVisible = Object.fromEntries(bks.map((bk) => [bk, false]));
    Object.values(links)
      .forEach(({ auth = () => true, relocation }) => {
        if (!auth(isAuthenticated)) return;
        const itemIsVisible = {};
        bks.forEach((bk) => {
          if (!(bk in relocation)) return;
          const isVisibleAtBreakpoint = relocation[bk] === location;
          for (let i = bks.indexOf(bk); i < bks.length; i++) {
            itemIsVisible[bks[i]] = isVisibleAtBreakpoint;
          }
        });
        bks.forEach((bk) => {
          if (itemIsVisible[bk]) groupIsVisible[bk] = true;
        });
      });
    groups[location].show = groupIsVisible;
  });
  return groups;
};
