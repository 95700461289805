import React, {
  useContext,
} from 'react';
import Img from 'next/image';
import Link from 'next/link';

import fullLogo from '@tmap/mmm-style-guide/src/common/mmm-full-logo.png';
import { AppBar } from '@tmap/mmm-style-guide/src/AppBar';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Toolbar } from '@tmap/mmm-style-guide/src/Toolbar';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import {
  MenuIcon,
} from '@tmap/mmm-style-guide/src/Icon';

import { fgColor } from './navConfig';
import HeaderContext from './HeaderContext';
import NavButton from './navButton';
import AuthButtons from './authButtons';
import HeaderDrawer from './headerDrawer';
import HeaderMenu from './headerMenu';

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (p) => !['color', 'elevation'].includes(p),
})(({ theme, color, elevation }) => ({
  position: 'absolute',
  color: fgColor(theme, color),
  backgroundColor: {
    transparent: 'transparent',
    default: theme.palette.common.white,
  }[color] || 'transparent',
  boxShadow: elevation > 0 ? (
    `0px ${1 * elevation}px ${8 * elevation}px 0px rgba(0, 0, 0, 0.12)`
  ) : undefined,
}));

const RelocNavGrid = styled(Grid, {
  shouldForwardProp: (p) => !['color', 'location', 'relocation'].includes(p),
})(({
  theme,
  color,
  location,
  relocation,
}) => ({
  color: fgColor(theme, color),
  display: 'none',
  ...Object.fromEntries(Object.entries(relocation).map(([bk, loc]) => ([
    theme.breakpoints.up(bk),
    { display: loc === location ? 'block' : 'none' },
  ]))),
}));

const MenuIconButton = styled(IconButton, {
  shouldForwardProp: (p) => !['color'].includes(p),
})(({ theme, color }) => ({
  color: fgColor(theme, color),
}));

export default function HeaderBar() {
  const {
    color,
    elevation,
    navLinks,
    isAuthenticated,
    handleOpenMenu,
  } = useContext(HeaderContext);
  return (
    <StyledAppBar color={color} elevation={elevation}>
      <Toolbar>
        <Grid
          container
          flexWrap='nowrap'
          paddingLeft={{ xs: 1, sm: 3 }}
          paddingRight={{ xs: 0, sm: 2 }}
          spacing={{ xs: 1, sm: 0 }}
        >
          <Grid item display='flex' alignItems='center'>
            <Link href='/'>
              <a href='/' style={{ display: 'flex', alignItems: 'center' }}>
                <Img
                  src={fullLogo}
                  alt='Make My Move'
                  width={2001 / 8}
                  height={262 / 8}
                  priority
                />
              </a>
            </Link>
          </Grid>
          <Grid item xs>
            <Grid
              container
              spacing={2}
              alignItems='center'
              flexWrap='nowrap'
            >
              <Grid item xs>
                <Grid container flexWrap='nowrap' justifyContent='end'>
                  {
                    Object.entries(navLinks)
                      .filter(([, { auth = () => true }]) => auth(isAuthenticated))
                      .map(([key, { relocation, label, button }]) => (
                        <RelocNavGrid
                          item
                          key={`reloc-main-${key}`}
                          color={color}
                          location='main'
                          relocation={relocation}
                        >
                          <NavButton {...button}>
                            {label}
                          </NavButton>
                        </RelocNavGrid>
                      ))
                  }
                </Grid>
              </Grid>
              {!isAuthenticated && (
                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <AuthButtons />
                </Grid>
              )}
              <Grid item>
                <MenuIconButton
                  onClick={handleOpenMenu}
                  aria-label='Global Menu'
                  color={color}
                >
                  <MenuIcon />
                </MenuIconButton>
                <HeaderDrawer />
                <HeaderMenu />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
}
