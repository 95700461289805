import React, {
  useContext,
} from 'react';

import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Button } from '@tmap/mmm-style-guide/src/Button';

import HeaderContext from './HeaderContext';

export default function AuthButtons() {
  const {
    handleSignUp,
    handleSignIn,
  } = useContext(HeaderContext);
  return (
    <Grid
      container
      flexWrap='nowrap'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Grid item xs>
        <Button
          fullWidth
          variant='contained'
          color='convert'
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleSignUp}
        >
          Sign Up
        </Button>
      </Grid>
      <Grid item xs>
        <Button
          fullWidth
          variant='outlined'
          color='convert'
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleSignIn}
        >
          Sign In
        </Button>
      </Grid>
    </Grid>
  );
}
