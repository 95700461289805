import React, {
  useContext,
} from 'react';

import { Menu } from '@tmap/mmm-style-guide/src/Menu';

import HeaderContext from './HeaderContext';
import RelocNavGroup from './relocNavGroup';

export default function HeaderMenu() {
  const {
    menuAnchor,
    isMenuOpen,
    handleCloseMenu,
    headerMenuSx,
  } = useContext(HeaderContext);
  return (
    <Menu
      anchorEl={menuAnchor}
      keepMounted
      open={isMenuOpen}
      onClose={handleCloseMenu}
      sx={headerMenuSx}
    >
      <RelocNavGroup noLabel location='first' />
      <RelocNavGroup noLabel location='browse' />
      <RelocNavGroup noLabel location='communities' />
      <RelocNavGroup noLabel location='resources' />
      <RelocNavGroup noLabel location='final' />
    </Menu>
  );
}
